angular.module('deitz').controller('rateDetailController', [

    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification','$uibModal','$http', 'apiUrl',

    function(
        $scope, $rootScope, $state, commonFactory, Notification, $uibModal, $http,
        apiUrl,

    ) {

        $scope.firm_cnt = 0;
        $scope.negotiated_y = 0;
        $scope.negotiated_n = 0;
        $scope.isEditMode = false;
        $scope.groupChargeList = [];
        $scope.BusinessUnitsList = [];
        $scope.type = 'I';
        //get all BussinesUnit for dropdown
        $rootScope.showLoader(true);
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $rootScope.showLoader(false);
        });
        if($state.params.id){
            $scope.isEditMode = true;
        }
       
    $scope.getgroupcharge = function(){
			$rootScope.showLoader(true);
			commonFactory.get('/api/get-group-charge')
			.success(function(response) {			
				if (response) {
					if (response.status == 200) {						
						$scope.groupChargeList = response.result.group_charges;						
					}					
					$rootScope.showLoader(false);
				}
			})
			.error(function(err) {
				$rootScope.showLoader(false);
			});
		}
		$scope.getgroupcharge();
      $scope.editRateCategoryPopup = function(id,name) {
            var rateCategoryModal = $uibModal.open({
              templateUrl: "modules/code_tables/rate_details/edit_rate_category_popup.html",
              controller: 'editRateCategoryController',
              resolve: {
                rateCategory: function() {
                  return {id:id,name:name,rate_id:$state.params.id};
                }
              },
              windowClass: 'full-width-model',
              keyboard: false,
              backdrop: false,
              size: 'lg',
              close: function() {}
            });
      
            rateCategoryModal.result.then(function(updatedRateCategory) {
              // Handle the updated rate category here
              console.log('Updated Rate Category:', updatedRateCategory);
            }, function() {
              console.log('Modal dismissed at: ' + new Date());
            });
          };
        $scope.change_rate_type =  function(type) { 
            $scope.formData={};
            var params = {};
            params.rate_id =type;
            params.id = $state.params.id
            $rootScope.showLoader(true);

            commonFactory.getWithParams('/api/rates/getRate', params)
                .success(function(response) {
                    if (response && response.result && response.result.rate_detail) {
                        $scope.formData.name = response.result.rate_detail.name;
                        $scope.formData.business_unit_id = response.result.rate_detail.business_unit_id;
                        $scope.type = response.result.rate_detail.type;
                        if($scope.type == 'R'){
                            document.getElementById("firms_tab").style.display = "none";
                        }

                        $rootScope.showLoader(false);

                        $scope.firm_cnt = response.result.firm_cnt;
                        $scope.negotiated_y = response.result.negotiated_y;
                        $scope.negotiated_n = response.result.negotiated_n;
                    } 
                })
                .error(function(err) {
                    console.log(err);
                    $rootScope.showLoader(false);
                });
        };

        $scope.save = function(){ 
            $rootScope.showLoader(true);
            $scope.formData.id = $state.params.id
            commonFactory.put('/api/rates/updateRateDetails/'+$state.params.id , $scope.formData)
            .success(function(response) {
                $rootScope.showLoader(false);
                Notification.success(response.message);
                if($scope.type == 'R'){
                    $state.go('rate_tables.resorces_rate_list', {}, { reload: true });
                }else{
                    $state.go('rate_tables.rate_list');
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.danger(err);
            });
            // let title = 'ARE YOU SURE?';
            // let txt = 'Are You Sure Want To Change Rates?';
            // if($scope.isEditMode){
            //     var cnt = 0;
            //     if($scope.formData.update_firms_rates == 'yes'){
            //         cnt = $scope.negotiated_y;
            //         title = "TOTAL <span style='color:red'> "+ $scope.negotiated_y +" FIRM(S)</span> HAS/HAVE THIS RATE TYPE WITH <span style='color:red'>NEGOTIATED YES</span>";
            //     }else if($scope.formData.update_firms_rates == 'no'){
            //         cnt = $scope.negotiated_n;
            //         title = "TOTAL <span style='color:red'> "+ $scope.negotiated_n +" FIRM(S)</span> HAS/HAVE THIS RATE TYPE WITH <span style='color:red'>NEGOTIATED NO</span>";
            //     }else{
            //         cnt = $scope.firm_cnt;
            //         title = "TOTAL <span style='color:red'> "+ $scope.firm_cnt +" FIRM(S)</span> HAS/HAVE THIS RATE TYPE";
            //     }
            //     if(cnt > 0){
            //         title += ', THAT ALL WILL BE AFFECTED';
            //         txt += " - It will take upto 5 minutes to process..";
            //     }
            // }
            // swal({
            //     title: title,
            //     text: txt,
            //     html:true,
            //     type: "info",
            //     showCancelButton: true,
            //     confirmButtonColor: "#09375f",
            //     confirmButtonText: "Yes",
            //     cancelButtonText: "No",
            //     closeOnConfirm: true,
            //     closeOnCancel: true
            // },
            // function (isConfirm) {
            //     if (isConfirm) {         
            //         $rootScope.showLoader(true);
            //         $scope.formData.id = $state.params.id
            //         commonFactory.put('/api/rates/updateRateDetails/'+ type, $scope.formData)
            //         .success(function(response) {
            //             $rootScope.showLoader(false);
            //             Notification.success(response.message);
            //             $state.go('rate_tables.rate_list');
            //         })
            //         .error(function(err) {
            //             $rootScope.showLoader(false);
            //             Notification.danger(err);
            //         });
            //     }
            // });      
        }
 
   
    }]);
