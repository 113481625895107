angular.module('deitz').controller('rateTypeBrowseController', [
    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile',
    function($scope, $rootScope, $state, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $compile) {
        
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.edit = function(id) {
            console.log('edit   ', id);
            $state.go('code_tables.rate_type_edit', { id: id });
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var params = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                
                commonFactory.getWithPaginate('/api/rate-types', params)
                    .success(function(response) {
                        if (response.status === 200) {
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.recordsFiltered,
                                data: response.result
                            });
                        }
                    });
            });
           
        $scope.dtColumns = [
            DTColumnBuilder.newColumn('code').withTitle('Code')
                .renderWith(function(data) {
                    return '<span style="text-transform: none !important;">' + data + '</span>';
                }),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('has_add_rates').withTitle('Has Add Rates')
                .renderWith(function(data) { return data ? 'Yes' : 'No' }),
            DTColumnBuilder.newColumn('charge.DESC').withTitle('Charge')
                .renderWith(function(data, type, full) { 
                    return !full.has_add_rates ? (data ? full.charge.CHG_CODE  +' | '+data : '-') : '-';
                }),
            DTColumnBuilder.newColumn('is_active').withTitle('Active')
                .renderWith(function(data) { return data ? 'Yes' : 'No' }),
            DTColumnBuilder.newColumn(null).withTitle('Actions')
                .renderWith(function(data) {
                    return '<button class="btn btn-primary btn-circle" ng-click="edit(' + data.id + ')">' +
                           '<i class="fa fa-pencil"></i></button>';
                })
        ];
        $scope.dtOptions.withOption('createdRow', function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        });

       
    }
    
]);
