angular.module('deitz').controller('editNsbTypeController', [
    '$scope', '$state', '$rootScope', 'commonFactory', 'Notification',
    function($scope, $state, $rootScope, commonFactory, Notification) {
        
        // Initialize arrays
        $scope.nsbType = {
            charges: []
        };
        $scope.allConnectionMethods = [];
        $scope.availableConnectionMethods = [];
        
        // Load the NSB type data
        if ($state.params.id) {
            $rootScope.showLoader(true);
            commonFactory.get('/api/nsb-types/' + $state.params.id + '/edit')
                .success(function(response) {
                    if (response.status === 200) {
                        $scope.nsbType = response.result;
                       

                        for (var i = 0; i < $scope.nsbType.charges.length; i++) {
                            $scope.nsbType.charges[i] = $scope.nsbType.charges[i].pivot;
                        }

                        // Code is read-only in edit mode
                        $scope.isEditMode = true;
                    }
                    $rootScope.showLoader(false);
                });
        } else {
            // In add mode, code is required and editable
            $scope.isEditMode = false;
        }

        // Load supporting data
        commonFactory.get('/api/rate-connection-methods').then(function(response) {
            if (response.data && response.data.result && response.data.result.data) {
                $scope.allConnectionMethods = response.data.result.data;
                // $scope.updateAvailableConnectionMethods();
            }
        }); 

        $rootScope.showLoader(true);
        commonFactory.get('/api/get-charge').success(function (response) {
          $rootScope.showLoader(false);
          $scope.additional_charges = response.result;
        });



        // Charge management functions
        $scope.addCharge = function() {
            $scope.nsbType.charges.push({});
          
        };

        $scope.removeCharge = function(index) {
            $scope.nsbType.charges.splice(index, 1);
           
        };

       

        // Save function
        $scope.save = function(isValid) {
            if (isValid) {
                $rootScope.showLoader(true);
                var savePromise = $state.params.id ? 
                    commonFactory.put('/api/nsb-types/' + $state.params.id, $scope.nsbType) :
                    commonFactory.post('/api/nsb-types', $scope.nsbType);

                savePromise.success(function(response) {
                    if (response.status === 200) {
                        Notification.success($state.params.id ? 
                            'NSB Type updated successfully' : 
                            'NSB Type created successfully'
                        );
                        $state.go('code_tables.nsb_type_browse');
                    } else {
                        Notification.error(response.result.message);
                    }
                    $rootScope.showLoader(false);
                });
            }
        };
    }
]);
