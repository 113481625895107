angular.module('deitz').controller('addRateController', [

    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification','$uibModal','$http', 'apiUrl',

    function(
        $scope, $rootScope, $state, commonFactory, Notification, $uibModal, $http,
        apiUrl,

    ) {

        $scope.groupChargeList = [];
        $scope.BusinessUnitsList = [];
        //get all BussinesUnit for dropdown
        $scope.formData={};
        console.log('addRateController',$state.params.type);
        if($state.params.type){
            $scope.formData.type = $state.params.type;
        }else{
            $scope.formData.type = 'I';
        }

        $rootScope.showLoader(true);
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $rootScope.showLoader(false);
        });
        
        
        $scope.save = function(){ 
            if($scope.formData.name && $scope.formData.business_unit_id){
            $rootScope.showLoader(true);
            commonFactory.post('/api/rates/createRateDetails', $scope.formData)
            .success(function(response) {
                $rootScope.showLoader(false);
                if(response.status == 400){
                    Notification.error(response);
                }
                else{
                    Notification.success(response);
                    if( $scope.formData.type == 'R'){
                        $state.go('rate_tables.resorces_rate_list', {}, { reload: true });
                    }else{
                        $state.go('rate_tables.rate_list');
                    }
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.error(err);
            });
        }else{
            Notification.error('Please Enter Name And Select Business Unit');
        }
    }
 
   
    }]);
