angular.module('deitz').controller('GroupChargeController', [
    '$scope', '$rootScope', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile',

    function(
        $scope, $rootScope, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $q, $compile
    ){        
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var table = $('#group_charge_list').DataTable();
            table.ajax.reload();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/group-charge', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [50,100,150,200,250],
                [50,100,150,200,250]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'OursuitesDetails' },
                { extend: 'pdf', title: 'OursuitesDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }
        $scope.getgroupcharge = function(){
			$rootScope.showLoader(true);
			commonFactory.get('/api/get-group-charge')
			.success(function(response) {			
				if (response) {
					if (response.status == 200) {						
						$scope.groupChargeList = response.result.group_charges;						
					}					
					$rootScope.showLoader(false);
				}
			})
			.error(function(err) {
				$rootScope.showLoader(false);
			});
		}
		$scope.getgroupcharge();
        $scope.mergeGroupCharges = function(isValid){ 
            if(isValid){
                $scope.reloadData();
                swal({
                        title: "<h3>Merge these Group Charge(s) - are you sure? Once this has begun you can not stop it..</h3>",
                        text: "<small style=' color:red';> Note : The merge will take some time. Please wait...</small>",
                        type: "warning",
                        html: true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No, Cancel this merge",
                        closeOnConfirm: true,
                        closeOnCancel: true 
                    },
                    function (isConfirm) { 
                        if (isConfirm) { 
                            $scope.delete_group_charges =  $scope.groupCharges.delete_group_charges || null;
                            $scope.keep_group_charges = $scope.groupCharges.keep_group_charges || null;

                            if($scope.delete_group_charges && $scope.keep_group_charges){
                                // if keep group charges not present in merge
                                if ($scope.delete_group_charges.indexOf($scope.keep_group_charges) < 0) {
                                    $rootScope.showLoader(true);  
                                    commonFactory.post('/api/group-charge/merge', {delete_group_charge:$scope.delete_group_charges,keep_group_charge:$scope.keep_group_charges}).success(function (response) {
                                            
                                        $rootScope.showLoader(false);
                                        $scope.groupCharges.delete_group_charges = null;
                                        $scope.groupCharges.keep_group_charges = null;
                                        $scope.getgroupcharge();
                                        $scope.reloadData();

                                        if(response.status == 200){
                                            Notification.success(response.result.message); 
                                        }else{
                                            Notification.error(response.result.message);
                                        } 
                                        
                                    }).error(function (error) {
                                        $rootScope.showLoader(false);
                                        Notification.error(error.message);
                                    });       
                                }else{
                                    Notification.error('You have selected keep group charges as a merge group charges as well, please select different.');
                                }
                            }
                        }
                    });
            }else{
                Notification.error('Please select keep and merge Group Charge');
            }
        }
        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Name'),
            DTColumnBuilder.newColumn('order_number').withOption('defaultContent', '').withOption('sortable', true).withTitle('Sort'),
            DTColumnBuilder.newColumn('active').withOption('defaultContent', '').withTitle('Active?'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="code_tables.group_charge_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;';
                }

            })
        ];

    }]);
