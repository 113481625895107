angular.module('deitz').controller('rateTypeController', [
    '$scope', '$state', '$rootScope', 'commonFactory', 'Notification',
    function($scope, $state, $rootScope, commonFactory, Notification) {
        $scope.rateType = {
            code: '',
            name: '',
            is_active: true,
            has_add_rates: false,
            charge_id: null
        };

        // Load additional charges for dropdown
        $rootScope.showLoader(true);
        commonFactory.get('/api/get-charge').success(function (response) {
            $rootScope.showLoader(false);
            $scope.charges = response.result;
        });

        // Load data if editing
        if ($state.params.id) {
            $rootScope.showLoader(true);
            commonFactory.get('/api/rate-types/' + $state.params.id)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status === 200) {
                        $scope.rateType = response.result;
                        $scope.isEditMode = true;
                    }
                });
        }

        $scope.save = function(isValid) {
            if (!isValid) return;

            $rootScope.showLoader(true);
            var savePromise = $state.params.id ? 
                commonFactory.put('/api/rate-types/' + $state.params.id, $scope.rateType) :
                commonFactory.post('/api/rate-types', $scope.rateType);

            savePromise.success(function(response) {
                $rootScope.showLoader(false);
                if (response.status === 200) {
                    Notification.success($state.params.id ? 
                        'Rate Type updated successfully' : 
                        'Rate Type created successfully'
                    );
                    $state.go('code_tables.rate_type_browse');
                } else {
                    Notification.error(response.result.message);
                }
            });
        };
    }
]);
