angular.module('deitz').controller('addNsbTypeController', [
    '$scope', '$state', '$rootScope', 'commonFactory', 'Notification',
    function($scope, $state, $rootScope, commonFactory, Notification) {
        $scope.nsbType = {
            code: '',
            name: '',
            split: 0,
            enclosed: 1,
            copies: 1,
            is_active: true,
            charges: []
        };
        
        $scope.formSubmit = false;
        
        // Load rate connection methods
        commonFactory.get('/api/rate-connection-methods').then(function(response) {
            if (response.data && response.data.result && response.data.result.data) {
                $scope.allConnectionMethods = response.data.result.data;
                // $scope.updateAvailableConnectionMethods();
            }
        });

        $rootScope.showLoader(true);
        commonFactory.get('/api/get-charge').success(function (response) {
          $rootScope.showLoader(false);
          $scope.additional_charges = response.result;
        });
        $scope.addCharge = function() {
            $scope.nsbType.charges.push({
                rate_connection_method_id: null,
                charge_id: null
            });
        };

        $scope.removeCharge = function(index) {
            $scope.nsbType.charges.splice(index, 1);
        };

        $scope.save = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/nsb-types', $scope.nsbType)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200) {
                            Notification.success("NSB Type added successfully");
                            $state.go("code_tables.nsb_type_browse");
                        } else {
                            Notification.error(response.result.message);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        Notification.error("Error occurred while saving");
                    });
            }
        };
    }
]);
