angular.module('deitz').controller('editEntityRateCategoryController', [
    '$scope',
    '$uibModalInstance',
    'rateCategory','$rootScope',
'$http', 'apiUrl','Notification',
    function($scope, $uibModalInstance, rateCategory,$rootScope, $http, apiUrl,Notification) {
      $scope.rateCategory = angular.copy(rateCategory);
      $rootScope.showLoader(true);
      $http.get(apiUrl + '/api/get-entity-rate-charge-by-group/'+ $scope.rateCategory.id +'/'+$scope.rateCategory.rate_id+'/'+ $scope.rateCategory.entity_rate_id).then(function(response) { 
          $scope.chargesList = response.data.result;  
          $rootScope.showLoader(false);
      });
      $scope.save = function() {
        // Perform save operation here
        $rootScope.showLoader(true);
        $http.post(apiUrl + '/api/rates/update-entity-rate-charges' ,{charges:$scope.chargesList}).success(function(response) {
            $rootScope.showLoader(false);
            if(response.status == 400){
                Notification.error(response);
            }
            else{
                Notification.success(response.message);
                $uibModalInstance.close($scope.rateCategory);
            }
        })
        .error(function(err) {
            $rootScope.showLoader(false);
            Notification.error(err);
        });
      
       
      };
  
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  ]); 