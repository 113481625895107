angular.module('deitz').controller('addRateController', [
    '$scope', '$state', '$rootScope', 'commonFactory', 'Notification',
    function($scope, $state, $rootScope, commonFactory, Notification) {
        $scope.addRate = {
            code: '',
            name: '',
            rate_type_id: null,
            charge_id: null,
            is_active: true
        };

        // Load rate types with add rates enabled
        $rootScope.showLoader(true);
        commonFactory.get('/api/rate-types-with-add-rates').success(function (response) {
            $scope.rateTypes = response.result;
        });

        // Load charges for dropdown
        commonFactory.get('/api/get-charge').success(function (response) {
            $rootScope.showLoader(false);
            $scope.charges = response.result;
        });

        // Load data if editing
        if ($state.params.id) {
            $rootScope.showLoader(true);
            commonFactory.get('/api/add-rates/' + $state.params.id)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status === 200) {
                        $scope.addRate = response.result;
                        $scope.isEditMode = true;
                    }
                });
        }

        $scope.save = function(isValid) {
            if (!isValid) return;

            $rootScope.showLoader(true);
            var savePromise = $state.params.id ? 
                commonFactory.put('/api/add-rates/' + $state.params.id, $scope.addRate) :
                commonFactory.post('/api/add-rates', $scope.addRate);

            savePromise.success(function(response) {
                $rootScope.showLoader(false);
                if (response.status === 200) {
                    Notification.success($state.params.id ? 
                        'Add Rate updated successfully' : 
                        'Add Rate created successfully'
                    );
                    $state.go('code_tables.add_rate_browse');
                } else {
                    Notification.error(response.result.message);
                }
            });
        };
    }
]);
