angular.module('deitz').controller('editFirmRateCategoryController', [
    '$scope',
    '$uibModalInstance',
    'rateCategory','$rootScope',
'$http', 'apiUrl','Notification','commonFactory',
    function($scope, $uibModalInstance, rateCategory,$rootScope, $http, apiUrl,Notification,commonFactory) {
      $scope.rateCategory = angular.copy(rateCategory);
      
      if($scope.rateCategory.search == true){
        $rootScope.showLoader(true);
        commonFactory.get('/api/get-charge').success(function (response) {
          $rootScope.showLoader(false);
          $scope.additional_charges = response.result;
        });
      }else{
        $rootScope.showLoader(true);
        $http.get(apiUrl + '/api/get-firm-rate-charge-by-group/'+ $scope.rateCategory.id +'/'+$scope.rateCategory.rate_id+'/'+ $scope.rateCategory.firm_id).then(function(response) { 
          $scope.chargesList = response.data.result;  
          $rootScope.showLoader(false);
        });
      }
      
      $scope.SelectCharges = function(id) {
        $rootScope.showLoader(true);
        $http.get(apiUrl + '/api/get-firm-rate-charge-by-group/'+ id +'/'+$scope.rateCategory.rate_id+'/'+ $scope.rateCategory.firm_id+'/true').then(function(response) { 
          $scope.chargesList = response.data.result;  
          $rootScope.showLoader(false);
          $scope.rateCategory.search = false;
        });
        
      }
      $scope.save = function() {
        // Perform save operation here
        $rootScope.showLoader(true);
        $http.post(apiUrl + '/api/rates/update-firm-rate-charges' ,{charges:$scope.chargesList}).success(function(response) {
            $rootScope.showLoader(false);
            if(response.status == 400){
                Notification.error(response);
            }
            else{
                Notification.success(response.message);
                $uibModalInstance.close($scope.rateCategory);
            }
        })
        .error(function(err) {
            $rootScope.showLoader(false);
            Notification.error(err);
        });
      
       
      };
  
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  ]); 